import React from "react";

export default [
  // Home
  { text: 'How to configure your first database backup',
    url: 'https://docs.simplebackups.com/database-backup/f43rJaVYoNkbCGWqr3j9Jb/how-to-create-serverless-backups-for-managed-databases/a6jedkfj9Xk7pq64fk7Xy5',
    'tags': ['home']
  },
  { text: 'Create your server (file) backup',
    url: 'https://docs.simplebackups.com/server--file-backup/fDHGxqzRa9Cu2SMtv4GVEP/create-a-fileserver-backup/bTjTnk3bdFkd9er6Kt1uce',
    'tags': ['home']
  },
  { text: 'Create your first Snapshot',
    url: 'https://docs.simplebackups.com/server--volume-snapshot/pUSRoC9qP6CAjzqvsHvJF9/',
    'tags': ['home']
  },
  { text: 'How to replicate your Storage (s3, Dropbox, ...)',
    url: '/blog/how-to-create-a-storage-replication-with-simplebackups/',
    'tags': ['home']
  },

  // MySQL
  { text: 'The Ultimate MySQL Database Backup Script', url: '/blog/the-ultimate-mysql-database-backup-script/', 'tags': ['mysql'] },
  { text: 'How to backup MySQL on AWS S3', url: '/blog/how-to-backup-mysql-to-aws-s3/', 'tags': ['mysql']},
  { text: 'How to backup MySQL on Backblaze', url: '/blog/how-to-backup-mysql-to-backblaze/', 'tags': ['mysql']},
  { text: 'How to backup MySQL on Dropbox', url: '/blog/how-to-backup-mysql-to-dropbox/', 'tags': ['mysql']},
  { text: 'How to backup MySQL on DigitalOcean', url: '/blog/how-to-backup-mysql-to-digitalocean-spaces/', 'tags': ['mysql']},
  { text: 'How to backup MySQL on Google Cloud Storage', url: '/blog/how-to-backup-mysql-to-google-cloud-storage-gcs/', 'tags': ['mysql']},
  { text: 'How to backup MySQL on Google Drive', url: '/blog/how-to-backup-mysql-to-google-drive/', 'tags': ['mysql']},
  { text: 'How to backup MySQL on Wasabi', url: '/blog/how-to-backup-mysql-to-wasabi/', 'tags': ['mysql']},
  { text: 'How to backup MySQL on Azure', url: '/blog/how-to-backup-mysql-to-azure-blob-storage/', 'tags': ['mysql']},
  { text: 'How to backup MySQL on Leviia', url: '/blog/how-to-backup-mysql-to-leviia/', 'tags': ['mysql']},

  // MongoDB
  {url: "/blog/the-ultimate-mongodb-database-backup-script/", text: "The Ultimate MongoDB Database Backup Script", tags: ['mongodb']},
  {url: "/blog/how-to-backup-mongodb-to-s3/", text: "How to backup MongoDB on AWS S3", tags: ['mongodb']},
  {url: "/blog/how-to-backup-mongodb-to-backblaze/", text: "How to backup MongoDB on Backblaze", tags: ['mongodb']},
  {url: "/blog/how-to-backup-mongodb-to-dropbox/", text: "How to backup MongoDB on Dropbox", tags: ['mongodb']},
  {url: "/blog/how-to-backup-mongodb-to-digitalocean/", text: "How to backup MongoDB on DigitalOcean", tags: ['mongodb']},
  {url: "/blog/how-to-backup-mongodb-to-google-cloud-storage-gcs/", text: "How to backup MongoDB on Google Cloud Storage", tags: ['mongodb']},
  {url: "/blog/how-to-backup-mongodb-to-google-drive/", text: "How to backup MongoDB on Google Drive", tags: ['mongodb']},
  {url: "/blog/how-to-backup-mongodb-to-wasabi/", text: "How to backup MongoDB on Wasabi", tags: ['mongodb']},
  {url: "/blog/how-to-backup-mongodb-to-filebase/", text: "How to backup MongoDB on Filebase", tags: ['mongodb']},
  {url: "/blog/how-to-backup-mongodb-to-azure-blob-storage/", text: "How to backup MongoDB on Azure", tags: ['mongodb']},


  // PostgreSQL
  {url: "https://simplebackups.com/docs/guide/database/postgresql/#guides", text: "PostgreSQL backup guide", tags: ['postgresql']},
  {url: "/blog/postgresql-pgdump-and-pgrestore-guide-examples/", text: "The Ultimate PostgreSQL Database Backup Script", tags: ['postgresql']},
  {url: "/blog/how-to-backup-postgres-to-s3/", text: "How to backup PostgreSQL on AWS S3", tags: ['postgresql']},
  {url: "/blog/how-to-backup-postgres-to-backblaze/", text: "How to backup PostgreSQL on Backblaze", tags: ['postgresql']},
  {url: "/blog/how-to-backup-postgresql-to-dropbox/", text: "How to backup PostgreSQL on Dropbox", tags: ['postgresql']},
  {url: "/blog/how-to-backup-postgres-to-digitalocean-spaces/", text: "How to backup PostgreSQL on DigitalOcean", tags: ['postgresql']},
  {url: "/blog/how-to-backup-postgresql-to-google-cloud-storage-gcs/", text: "How to backup PostgreSQL on Google Cloud Storage", tags: ['postgresql']},
  {url: "/blog/how-to-backup-postgresql-to-google-drive/", text: "How to backup PostgreSQL on Google Drive", tags: ['postgresql']},
  {url: "/blog/how-to-backup-postgres-to-wasabi/", text: "How to backup PostgreSQL on Wasabi", tags: ['postgresql']},
  {url: "/blog/how-to-backup-postgres-to-azure-blob-storage/", text: "How to backup PostgreSQL on Azure", tags: ['postgresql']},
  
  // Redis
  {url: "https://docs.simplebackups.com/database-backup/f43rJaVYoNkbCGWqr3j9Jb/digitalocean-managed-redis/tsSgysfXqVYfCUQ8GP1LZv", text: "Connect DigitalOcean Managed Redis to SimpleBackups.", tags: ['redis']},
  {url: "https://docs.simplebackups.com/database-backup/f43rJaVYoNkbCGWqr3j9Jb/restore-a-redis-backup/aGGDek7aMmxgNSdEwUpUUi", text: "How to Restore a Redis Backup.", tags: ['redis']},
  {url: "https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/backup-workers/pszm9dtdbd9Ptq8QH2WB8g", text: "What is a Serverless Database Backup?", tags: ['redis']},

  // Server
  {url: "https://simplebackups.com/docs/guide/getting-started/user-guides.html#file-backup", text: "Get started with file backups with our guide", tags: ['server']},
  {url: "https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/backup-storage/7mmC9PmjPjx9Kj2Ms6eznr", text: "Get started with cloud storage for backups.", tags: ['server']},

  // Snapshot
  {url: "/blog/backups-vs-snapshots-with-differences-and-examples/", text: "Backups vs Snapshots: Differences and Examples", tags: ['snapshot']},
  {url: "/blog/how-to-automate-digitalocean-server-and-volume-snapshots/", text: "How to automate DigitalOcean Server and Volume Snapshots", tags: ['do-snapshot']},
  {url: "/blog/how-to-automate-hetzner-server-snapshots/", text: "How to automate Hetzner Server and Volume Snapshots", tags: ['hetzner-snapshot']},
  {url: "/blog/how-to-automate-linode-instance-snapshots/", text: "How to automate Linode (Akamai) Instance Snapshots", tags: ['linode-snapshot']},

  // Storage Replication
  {url: "/blog/how-to-create-a-storage-replication-with-simplebackups/", text: "How to Create a Storage Replication", tags: ['storage-replication']},
  {url: "/blog/how-to-replicate-your-storage-s3-dropbox-with-simplebackups/", text: "How to replicate your Storage (s3, Dropbox, ...)", tags: ['storage-replication']},
  {url: "/blog/back-up-your-aws-s3-bucket-to-backblaze/", text: "Sync AWS S3 Bucket to Backblaze B2", tags: ['storage-replication', 'backblaze', 'aws-s3']},
  {url: "/blog/back-up-your-aws-s3-bucket-to-storj/", text: "Sync AWS S3 Bucket to Storj", tags: ['storage-replication', 'storj', 'aws-s3']},

  // SaaS Backup
  {url: "/blog/how-to-back-up-your-knack-data/", text: "How to Back up Your Knack Data", tags: ['saas-knack']},
  {url: "/blog/how-to-back-up-your-github-data/", text: "How to Back up Your GitHub Data", tags: ['saas-github']},
  {url: "/images/uploads/github-checklist.pdf", text: "GitHub Backup & Compliance Checklist (PDF)", tags: ['saas-github']},
  {url: "/blog/the-ultimate-gitlab-backup-script/", text: "The Ultimate GitLab Backup Script", tags: ['saas-gitlab']},
  {url: "/blog/how-to-backup-your-gitlab-data/", text: "How to Back up Your GitLab Data", tags: ['saas-gitlab']},
  {url: "/security-first/", text: "Data Security and your backups", tags: ['saas-backup']},
  {url: "https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/data-processing-agreement/jm2Dp8guGKG2XMYado1ujJ", text: "Your backups and GDPR", tags: ['saas-backup']},
  {url: "/", text: "The 3-2-1 backup strategy explained", tags: ['saas-backup']},
  {url: "/", text: "Grandfather-father-son strategy for your backups", tags: ['saas-backup']},

  // Storage
  // AWS S3
  {url: "https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/aws-s3/5Tkw1bGWHJvVgQ9gb4hmQu", text: "How to store your backup on Amazon AWS", tags: ['storage', 'aws-s3']},
  {url: "https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/aws-s3/5Tkw1bGWHJvVgQ9gb4hmQu", text: "How to create Amazon S3 credentials?", tags: ['storage', 'aws-s3']},
  {url: "/blog/how-to-backup-mysql-to-aws-s3/", text: "How to back up MySQL to AWS S3", tags: ['storage', 'aws-s3']},
  {url: "/blog/how-to-backup-mongodb-to-s3/", text: "How to back up MongoDB to AWS S3", tags: ['storage', 'aws-s3']},
  {url: "/blog/how-to-backup-postgres-to-s3/", text: "How to back up Postgres to AWS S3", tags: ['storage', 'aws-s3']},
  {url: "/blog/mastering-s3-sync-s3cmd-rclone-ultimate-guide/", text: "Mastering S3 Sync with Examples", tags: ['storage', 'aws-s3']},
  // Azure
  {url: "/blog/create-azure-blob-storage-credentials-key-secret/", text: "How to create Azure Blob Storage credentials", tags: ['storage', 'azure']},
  {url: "/blog/how-to-backup-mysql-to-azure-blob-storage/", text: "How to back up MySQL to Azure Blob Storage", tags: ['storage', 'azure']},
  {url: "/blog/how-to-backup-mongodb-to-azure-blob-storage/", text: "How to back up MongoDB to Azure Blob Storage", tags: ['storage', 'azure']},
  {url: "/blog/how-to-backup-postgres-to-azure-blob-storage/", text: "How to back up Postgres to Azure Blob Storage", tags: ['storage', 'azure']},
  // Backblaze
  {url: "https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/backblaze-b2/9kVwRZgPfWEnsCTHq2YSug", text: "How to create Backblaze credentials", tags: ['storage', 'backblaze']},
  {url: "/blog/how-to-backup-mysql-to-backblaze/", text: "How to back up MySQL to Backblaze", tags: ['storage', 'backblaze']},
  {url: "/blog/how-to-backup-mongodb-to-backblaze/", text: "How to back up MongoDB to Backblaze", tags: ['storage', 'backblaze']},
  {url: "/blog/how-to-backup-postgres-to-backblaze/", text: "How to back up Postgres to Backblaze", tags: ['storage', 'backblaze']},
  {url: "/blog/how-to-backup-postgres-to-backblaze/", text: "How to back up Postgres to Backblaze", tags: ['storage', 'backblaze']},
  // Synology
  {url: "https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/synology-c2/h4P4qcJDPdn2sMJxqFJnVX", text: "How to create Synology C2 credentials", tags: ['storage', 'synology']},
  // DigitalOcean
  {url: "https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/digitalocean-spaces/spMiZQstmUZMBc9uaAmGHf", text: "How to store your backup on DigitalOcean Spaces", tags: ['storage', 'digitalocean']},
  {url: "https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/digitalocean-spaces/spMiZQstmUZMBc9uaAmGHf", text: "How to create DigitalOcean Spaces credentials?", tags: ['storage', 'digitalocean']},
  {url: "/blog/how-to-backup-mysql-to-digitalocean-spaces/", text: "How to back up MySQL to DigitalOcean Spaces", tags: ['storage', 'digitalocean']},
  {url: "/blog/how-to-backup-mongodb-to-digitalocean/", text: "How to back up MongoDB to DigitalOcean Spaces", tags: ['storage', 'digitalocean']},
  {url: "/blog/how-to-backup-postgres-to-digitalocean-spaces/", text: "How to back up Postgres to DigitalOcean Spaces", tags: ['storage', 'digitalocean']},

  // Dropbox
  {url: "https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/dropbox/nZEHLr3V3D1jHMtnbh29GY", text: "How to store your backups on Dropbox.", tags: ['storage', 'dropbox']},
  {url: "/blog/how-to-backup-mysql-to-dropbox/", text: "How to back up MySQL to Dropbox", tags: ['storage', 'dropbox']},
  {url: "/blog/how-to-backup-mongodb-to-dropbox/", text: "How to back up MongoDB to Dropbox", tags: ['storage', 'dropbox']},
  {url: "/blog/how-to-backup-postgresql-to-dropbox/", text: "How to back up Postgres to Dropbox", tags: ['storage', 'dropbox']},
  // Exoscale
  {url: "/blog/how-to-backup-files-to-exoscale/", text: "How to back up Files to Exoscale", tags: ['storage', 'exoscale']},
  {url: "/blog/how-to-backup-mysql-to-exoscale/", text: "How to back up MySQL to Exoscale", tags: ['storage', 'exoscale']},
  // Filebase
  {url: "/blog/filebase-integration-with-simplebackups-and-the-blockchain/", text: "Filebase integration with SimpleBackups and the Blockchain", tags: ['storage', 'filebase']},
  {url: "/blog/create-filebase-storage-credentials-access-key-secret-key/", text: "Create Filebase Storage Credentials.", tags: ['storage', 'filebase']},
  {url: "/blog/how-to-backup-mysql-to-filebase/", text: "How to back up MySQL to Filebase", tags: ['storage', 'filebase']},
  {url: "/blog/how-to-backup-mongodb-to-filebase/", text: "How to back up MongoDB to Filebase", tags: ['storage', 'filebase']},
  {url: "/blog/how-to-backup-postgres-to-filebase/", text: "How to back up Postgres to Filebase", tags: ['storage', 'filebase']},
  // GoogleDrive
  {url: "/blog/how-to-backup-files-to-google-drive/", text: "How to back up Files to Google Drive", tags: ['storage', 'googledrive']},
  {url: "/blog/how-to-backup-mysql-to-google-drive/", text: "How to back up MySQL to Google Drive", tags: ['storage', 'googledrive']},
  // GoogleCloudStorage
  {url: "https://simplebackups.com/blog/how-to-connect-google-cloud-storage/", text: "How to create Google Cloud Storage credentials", tags: ['storage', 'google-cloud-storage']},
  {url: "https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/google-cloud-storage/qYCmhYSxmquPEH3ARwRTnV", text: "How to store your backups on Google Storage", tags: ['storage', 'google-cloud-storage']},
  {url: "/blog/how-to-backup-mysql-to-google-cloud-storage-gcs/", text: "How to back up MySQL to Google Cloud Storage", tags: ['storage', 'google-cloud-storage']},
  {url: "/blog/how-to-backup-mongodb-to-google-cloud-storage-gcs/", text: "How to back up MongoDB to Google Cloud Storage", tags: ['storage', 'google-cloud-storage']},
  {url: "/blog/how-to-backup-postgresql-to-google-cloud-storage-gcs/", text: "How to back up Postgres to Google Cloud Storage", tags: ['storage', 'google-cloud-storage']},
  // IDrive
  {url: "https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/idrive-e2/u6KZB3E4hpugLFxBFF4uNi", text: "How to create an IDrive e2 bucket to store your backups", tags: ['storage', 'idrive-e2']},
  // simpleStorage
  {url: "/blog/how-to-backup-mysql-to-simplestorage/", text: "How to back up MySQL to SimpleStorage", tags: ['storage', 'simplestorage']},
  {url: "/blog/how-to-backup-mongodb-to-simplestorage/", text: "How to back up MongoDB to SimpleStorage", tags: ['storage', 'simplestorage']},
  {url: "/blog/how-to-backup-postgres-to-simplestorage/", text: "How to back up Postgres to SimpleStorage", tags: ['storage', 'simplestorage']},
  // UpCloud
  {url: "/blog/how-to-backup-mysql-to-upcloud/", text: "How to back up MySQL to UpCloud", tags: ['storage', 'upcloud']},
  {url: "/blog/how-to-backup-files-to-upcloud/", text: "How to back up Files to UpCloud", tags: ['storage', 'upcloud']},
  // Wasabi
  {url: "/blog/how-to-connect-wasabi/", text: "How to create Wasabi credentials", tags: ['storage', 'wasabi']},
  {url: "/blog/how-to-backup-mysql-to-wasabi/", text: "How to back up MySQL to Wasabi", tags: ['storage', 'wasabi']},
  {url: "/blog/how-to-backup-mongodb-to-wasabi/", text: "How to back up MongoDB to Wasabi", tags: ['storage', 'wasabi']},
  {url: "/blog/how-to-backup-postgres-to-wasabi/", text: "How to back up Postgres to Wasabi", tags: ['storage', 'wasabi']},

  // Cloudflare
  {url: "https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/cloudflare-r2/v6fgKR1Yj5yyjw1eFU6tix", text: "How to create CloudFlare R2 credentials", tags: ['storage', 'cloudflare']},

  // Security
  {url: "https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/how-can-i-restrictlimit-the-permissions-provided-to-simplebackups/hPKfLyGt138KJ8Wn56wX8G", text: "What happens if my account on SimpleBackups is compromised?", tags: ['security']},
  {url: "/privacy/#GDPR", text: "SimpleBackups and the GDPR", tags: ['security']},
  {url: "/gdpr-sheet", text: "GDPR Simplified Sheet", tags: ['security']},
  {url: "https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/data-processing-agreement/jm2Dp8guGKG2XMYado1ujJ", text: "Data Processing Agreement", tags: ['security']},


  // PlanetScale
  {url: "https://docs.simplebackups.com/database-backup/f43rJaVYoNkbCGWqr3j9Jb/restore-planetscale--vitess-mysql-backups/dgrSSBkNwU7Ah9JcBaUXYJ", text: "How to Restore PlanetScale / Vitess MySQL Backups", tags: ['planetscale']},

  // Supabase
  {url: "/blog/how-to-backup-supabase/", text: "How to backup and restore Supabase Backups", tags: ['supabase']},

  // Neon
  {url: "/blog/how-to-backup-neon/", text: "How to backup and restore Neon databases", tags: ['neon']},

]