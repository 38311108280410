import React from 'react'

export default [
  {
    avatar: 'testimonials-wb.png',
    name: 'Will',
    role: '',
    company: 'Sidekick Digital',
    content: `The simplest products are almost always the best, and this epitomises that. <span class="link-highlight">I highly recommend the team working on SimpleBackups</span>.`,
    tags: []
  },
  {
    avatar:"testimonials-default.png",
    name:"Matt",
    role:"Webmaster",
    content: "<span class=\"link-highlight\">We switched from CloudBack</span>! Better pricing model, and backing up databases AND GitHub Repos let us get rid of separate solutions for each",
    tags: ['support']
  },
  {
    avatar:"testimonials-default.png",
    name:"Mikko",
    role:"CEO",
    content: "Easy to create backup jobs - <span class=\"link-highlight\">easy to restore from backups</span>.",
    tags: []
  },
  {
    avatar:"testimonials-default.png",
    name:"Joe O.",
    role:"Software Engineer",
    content: "It offers exactly what we needed. <span class=\"link-highlight\">We need to be able to backup between different cloud storage points and it works perfectly for that</span>. The interface offers just the right level of customization.",
    tags: []
  },
  {
    avatar:"testimonials-omar-pb.png",
    name:"Omar Dahroug",
    role:"Co-Founder @ProperBird",
    content:"Awesome! <span class=\"link-highlight\">Super happy to see how customer-centric you guys are</span>!",
    tags: ['support']
  },
  {
    avatar:"testimonials-default.png",
    name:"Vladyslav K.",
    role:"AI Engineer",
    content:"Your service really did a nice job to save data so we dont have to waste time again. <span class=\"link-highlight\">Amazing service</span>.",
    tags: ['support']
  },
  ,{
    avatar:"testimonials-default.png",
    name:"Gabriel",
    role:"CTO",
    company:"Octoreach",
    content:"SimpleBackups has been so amazing for us! We can't believe how easy it was to add backups to our Vultr instances!",
    tags: []
  },
  {
    avatar:"testimonials-default.png",
    name:"Mikko",
    role:"CEO",
    content: "Easy to create backup jobs - <span class=\"link-highlight\">easy to restore from backups</span>.",
    tags: []
  },
  {
    avatar:"testimonials-default.png",
    name:"Brandon Tooke",
    role:"Software Engineer",
    content:"Your service has been excellent when it comes to performing and managing logical backups for my MySQL databases. <span class=\"link-highlight\">It provides peace of mind</span> to have a proper interface to manage it all.",
    tags: ['mysql', 'database']
  },
  {
    avatar:"testimonials-default.png",
    name:"Hugo R.",
    role: "Head of Digital",
    content: "Very easy and quick to set up and monitor. <span class=\"link-highlight\">The solution needed to enhance digital ocean backup</span>.",
    tags: ['digitalocean']
  },
  {
    avatar: 'testimonials-cs.png',
    name: 'Chris Soriano',
    role: '',
    company: 'Clever Talks',
    content:"I did not want to install extra packages on my servers to do backups or sync to other cloud services (...). I tested SimpleBackups and ... <span class=\"link-highlight\">bam it just works!</span>",
    tags: []
  },
  {
    avatar:"testimonials-corby.jpeg",
    name:"Corby Wenzelbach",
    role:"LS Graphics Inc.",
    content:"Simplebackups has been a great tool for our web development company. We currently have over 200 backups setup across 60 different webservers - <span class=\"link-highlight\">all running automated backups</span> 👌!",
    tags: []
  }
  ,{
    avatar:"testimonials-taddei.jpg",
    name:"Marco Taddei",
    role:"App Developer - Fairfield Public Schools",
    content:"SimpleBackups provides a much-needed backup <span class=\"link-highlight\">solution for our critical databases</span>. We run dozens of applications that have complex setups and privacy concerns, and SimpleBackups sync configuration are hands down the best on the market for complex setups.",
    tags: []
  }
  ,{
    avatar:"testimonials-mr.jpeg",
    name:"Mikko Raasina",
    role:"CEO at Affordia Oy",
    content:"SimpleBackups, as the name implies, is an excellent service that makes it very simple for you to <span class=\"link-highlight\">automate your cloud storage backups</span>. Easy to use, caring customer service. I would absolutely recommend it.",
    tags: []
  }
  ,{
    avatar:"testimonials-johna.jpg",
    name:"John A.",
    role:"Founder, CTO",
    content:`<span class="link-highlight">Simple to use, affordable, good support</span>. As the name implies, it's really easy to set up air-gapped (offsite) backups. We're backing up our RDS database in object-locked storage, especially as an emergency/ransomware backup scenario.`,
    tags: []
  }
  ,{
    avatar:"testimonials-philb.jpg",
    name:"Philip B.",
    role:"Web Developer",
    content:`Fantastic, <span class="link-highlight">it is feature rich with a simple interface and great support</span>.`,
    tags: []
  }
  ,{
    avatar:"testimonials-christianb.jpg",
    name:"Christian B.",
    role:"Director of IT at Business",
    content:`Great, easy to use, and flexible options. We love the simplicity of installing and managing backup jobs through the web interface. <span class="link-highlight">Flexible storage options to Wasabi</span>. Nice email alerts. Backs up our servers and PostgreSQL db without issues.`,
    tags: []
  }
  ,{
    avatar:"testimonials-olivierk.jpg",
    name:"Olivier K.",
    role:"Founder at Business",
    content:`I was looking for an easy backup solution for my clients MySQL databases. In a matter of minutes, all was up and running and my DBs was backuped securely to my Dropbox account. <span class="link-highlight">It simply works as promised, easy and reliable!</span> Additional their support is exceptional, they actually care for their clients.`,
    //content:`I was looking for an easy backup solution for my clients MySQL databases. After playing around with a couple of other sites which was either too complicated or too expensive for me I found SimpleBackups. In a matter of minutes, all was up and running and my DBs was backuped securely to my Dropbox account. I was looking for a solution to have my DBs on my personal Dropbox account. Later I adapted to their own cloud space which also worked after only a couple of clicks. It simply works as promised, easy and reliable! Additional their support is exceptional and I got very fast help for doubts in their internal chat and via email as well. Today this is not as normal as it should be but they actually care for their clients.`,
    tags: ['long']
  }
  ,{
    name:"Daniel E.",
    role:"CTO at Business",
    content:`<span class="link-highlight">Great and easy system for all backups</span>. The system is so easy to use (you can configure your backups with ssh in 10 minutes), and the support is 10/10. You can get support and resolve all your issues / doubts in a few minutes.`,
    tags: []
  }
  ,{
    name:"Paul Rogers",
    role:"Developer",
    content:"Just want to say that it looks like you guys have a great product! <span class=\"link-highlight\">Been using it for a little while and so far it's the best I've used</span>.",
    tags: []
  }
  ,{
    name:"Greg Freeman",
    role:"Developer",
    content:"You guys offer <span class=\"link-highlight\">the most responsive service I’ve seen</span>.",
    tags: []
  }
  ,{
    name:"Bogdan",
    role:"President",
    company: "Infear",
    content:"Backups of databases are generally too complicated, specially MongoDB (which we use for anxiety tests), but thanks to SimpleBackups, we just setup a job in dashboard and the rest will take care of itself. <span class=\"link-highlight\">Fantastic life-saver</span>!",
    tags: []
  }
  ,{
    name:"Ahmad Taha",
    role:"Applications Developer Fairfield Public Schools",
    content:"SimpleBackups is awesome and very simple to use for backup solutions. We use it for all our projects, and have had an incredible experience so far. Even if I can make my own database backup solution, I'd prefer SimpleBackups due to the <span class=\"link-highlight\">exceptional peace of mind it gives</spna>. The pricing is also very good and competitive.",
    tags: []
  }
  ,{
    avatar:"testimonials-default.png",
    name:"Mike Oldfield",
    role:"Developer",
    content:"Express setup is awesome!!",
    tags: []
  }


]